












































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AutoLinkBuilder extends Vue {
  userFirstName: string | null = "";
  userLastName: string | null = "";
  userEmail: string | null = "";
  workspaceName: string | null = "";

  linkResult: string | null = null;

  generateLink() {
    let uName = "";
    let lName = "";
    let uEmail = "";
    let wName = "";

    if(this.userFirstName) {
      uName = 'u_name=' + this.userFirstName + '&';
    }
    if(this.userLastName) {
      lName = 'u_lastname=' + this.userLastName + '&';
    }
    if(this.userEmail) {
      uEmail = 'u_email=' + this.userEmail + '&';
    }
    if(this.workspaceName) {
      wName = 'w_name=' + this.workspaceName + '&';
    }

    let result = '/auto-reg' + '?' +  uName + lName + uEmail + wName;

    this.linkResult = result;

  }

  copyLink() {
    //@ts-ignore
    let textToCopy = this.$refs.resultLink.$el.querySelector('input')
    textToCopy.select()
    document.execCommand("copy");
    this.$buefy.toast.open({
      message: 'Copied the referral link',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }
}
